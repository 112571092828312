import { useRef, useState,useEffect } from "react";
import Sheet from '@mui/joy/Sheet';
import { Grid } from "@mui/joy";
import { styled } from '@mui/joy/styles';
import { motion, useInView } from "framer-motion";
import { PRODUCTS } from "../constants";
import { useParams } from "react-router-dom";
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { BODYFONT } from "../constants";
import InventoryIcon from '@mui/icons-material/Inventory';
import Button from '@mui/joy/Button';
import EnquireForm from "../enquireform";
import { useNavigate } from "react-router-dom";

const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
  }));

export default function MainProduct() {
    const navigate = useNavigate();
    const {productname} = useParams();
    const [layout, setLayout] = useState(undefined);
    const productDetails = [];
        PRODUCTS.forEach(element => {
            if(element[productname]) {
                productDetails.push(element[productname]);
            }
        });
    
        return(<>
            <Grid container spacing={2} columns={16} sx={{ flexGrow: 1, paddingLeft:'20px'}}>
                <Grid xs={16} md={8} lg={8} ><Item>
        <Box
      
      sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0 }}
    >
      <Card sx={{ minWidth: 300,minHeight:300, flexGrow: 1 }} orientation="vertical">
        <CardCover>
          <img
            src={productDetails[0].variantSource}
            srcSet={productDetails[0].variantSource}
            loading="lazy"
            alt=""
          />
        </CardCover>

        <CardContent>
                    
                   
                    
                  </CardContent>




      </Card>
     
      </Box>
      
      </Item>
      </Grid>

      <Grid xs={16} md={8} lg={8} ><Item>
        <Box
      
      sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0 }}
    >
      <Card sx={{ minWidth: 300,minHeight:300, flexGrow: 1 }}>
      <div>
        <Typography level="title-md" sx={{fontFamily:BODYFONT}}>{productDetails[0].variantName}</Typography>
      </div>
      <div>
        <Typography level="title-sm" sx={{fontFamily:BODYFONT,fontSize:'11px'}}>{productDetails[0].variantDescription}</Typography>
      </div>
        <CardContent>
        <Sheet
                      sx={{
                        borderRadius: "sm",
                        p: 1.5,
                        my: 1.5,
                        display: "flex",
                        gap: 2,
                        "& > div": { flex: 1 },
                        fontFamily: BODYFONT,
                      }}
                    >
                      <div>
                        <Typography
                          level="body-xs"
                          fontWeight="md"
                          fontFamily={BODYFONT}
                        >
 Get your hands on the freshest produce possible. We utilize air cargo to ensure the fastest possible delivery, minimizing travel time and preserving peak quality.
 Our air cargo option is perfect for delicate vegetables with short shelf lives, like leafy greens, asparagus, or berries. They arrive crisp, vibrant, and bursting with flavor.
                        </Typography>
                      </div>
                    </Sheet>
        </CardContent>
      </Card>
     
      </Box>
      
      </Item>
      </Grid>

      <Grid xs={16} md={8} lg={8} ><Item>

      <Box
                      sx={{
                        display: "flex",
                        gap: 1.5,
                        "& > button": { flex: 1 },
                      }}
                    >
                      <Button
                        variant="soft"
                        color="primary"
                        size="sm"
                        sx={{ fontFamily: BODYFONT, fontSize: "12px" }}
                        onClick={() => {
                          navigate('/quote/'+productname);
                        }}
                      >
                        Get Quote
                      </Button>
                    </Box>
</Item></Grid>


      </Grid>
      
      
      </>);
   
}