import * as React from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import OtherHousesRoundedIcon from '@mui/icons-material/OtherHousesRounded';

import { Link, BrowserRouter as Router, useLocation } from "react-router-dom";
import { ICONCOLORS,BODYFONT } from './constants';

export default function HorizontalList() {
  return (
    <Box component="nav" aria-label="My site" sx={{ flexGrow: 1,fontFamily:BODYFONT }}>
      <List role="menubar" orientation="horizontal">
        <ListItem role="none">
        <Link to='/' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
          <ListItemButton
            role="menuitem"
            component="a"
            aria-label="Home"
          > <OtherHousesRoundedIcon sx={{ color:ICONCOLORS }}/> 
           Home
          </ListItemButton></Link>
        </ListItem>
        <ListDivider sx={{ backgroundColor: '#d2ebeb' }}/>
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
          <ListItemButton role="menuitem">
          <Link to='/fruits' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >Fruits</Link>
          </ListItemButton>
        </ListItem>
        <ListDivider sx={{ backgroundColor: '#d2ebeb' }}/>
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
          <ListItemButton role="menuitem">
          <Link to='/millets' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >Millets</Link>
          </ListItemButton>
        </ListItem>
       <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
          <ListItemButton role="menuitem">
          <Link to='/vegetables' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >Vegetables</Link>
          </ListItemButton>
        </ListItem>
        <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'14px'}}>
          <ListItemButton role="menuitem">
          <Link to='/aboutus' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >About Us</Link>
          </ListItemButton>
        </ListItem>
        <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'14px'}}>
          <ListItemButton role="menuitem">
          <Link to='/contactus' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >Contact Us</Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
