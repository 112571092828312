import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Millets from './component/millets/millets';
import { BrowserRouter } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route, Redirect ,withRouter 
} from "react-router-dom";
import MainProduct from './component/productboxes/singleitemmain';
import EnquireForm from './component/enquireform';
import AboutUs from './component/aboutus';
import ContactUs from './component/contactus';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={ <App /> }>
      <Route path="/:product" element={<Millets/>}/>
      <Route path="/product/:productname" element={<MainProduct/>}/>
      <Route path="/quote/:productname" element={<EnquireForm/>}/>
      <Route path="/aboutus" element={<AboutUs/>}/>
      <Route path="/contactus" element={<ContactUs/>}/>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
