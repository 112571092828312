import * as React from 'react';

import Badge from '@mui/joy/Badge';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Dropdown from '@mui/joy/Dropdown';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import ListDivider from '@mui/joy/ListDivider';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Chip from '@mui/joy/Chip';
import AddIcon from '@mui/icons-material/Add';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import logo from './images/logo.jpeg';
import NavigationMenu from './navbar';
import Stack from '@mui/joy/Stack';
import { styled } from '@mui/joy/styles';
import HorizontalList from './menulist';
import DrawerMobileNavigation from './mobilenav';

export default function Header() {
  
  return (<>
    <Sheet
      color='white'
      invertedColors
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        p: 2,
        minWidth: 'min-content',
        float:'right',
       
       
      }}
    >
         <Stack
        direction={{ xs: 'column', sm: 'column' }}
        spacing={{ xs: 0.1, sm: 0.1, md: 0.1 }}
        style={{maxWidth:'fit-content'}}
      >
        <img src={logo} style={{borderRadius:'5px'}}/>
       
        </Stack>
     </Sheet>
     
     <Box 
     sx={theme => ({
      [theme.breakpoints.down('md')]: {
     display:'none'
      },flex: 1, maxWidth:'97vw', display: 'flex', borderBottom:'1.5px solid #d2ebeb',maxWidth: '-webkit-fill-available',borderRadius:'5px'
    })}
     >
     <HorizontalList/>
      </Box>
      <Box sx={theme => ({
      [theme.breakpoints.down('md')]: {
         display:'block'
      },
      [theme.breakpoints.up('sm')]: {
        display:'none'
     }
      ,flex: 1, width:'inherit',float:'right', display: 'flex', borderBottom:'1.5px solid #d2ebeb',borderRadius:'5px'
    })}>

      <DrawerMobileNavigation/>
      </Box>
      
      </>
  );
}