import * as React from 'react';

import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GitHubIcon from '@mui/icons-material/GitHub';
import SendIcon from '@mui/icons-material/Send';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import footerlogo from '../logo-bg.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { BODYFONT } from '../constants';
import { useNavigate } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
export default function ColorInversionFooter() {
 const navigate = useNavigate();
  return (
    <Sheet
      variant="#e3effb"
      color='#e3effb'
      invertedColors
      sx={{
        bgcolor:'#e3effb'
        }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Divider orientation="vertical" />
        
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { md: 'flex-start' },
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 2,
        float:'right',
        width: { xs: '89%',sm:'89%' }
        }}
      >
        <List
          size="sm"
          orientation="horizontal"
          wrap
          sx={{ flexGrow: 0, '--ListItem-radius': '8px' }}
        >
          <ListItem nested sx={{ width: { xs: '50%', md: 140 } }}>
            <ListSubheader sx={{ fontWeight: 'xl',fontFamily:BODYFONT }}>Company</ListSubheader>
            <List>
              <ListItem>
                <ListItemButton style={{fontFamily:BODYFONT,fontSize:'12px'}} onClick={()=>{
                          navigate('/aboutus');
                        }}>About Us</ListItemButton>
              </ListItem>
            

            </List>
          </ListItem>
          <ListItem nested sx={{ width: { xs: '50%', md: 180 } }}>
            <ListSubheader sx={{ fontWeight: 'xl',fontFamily:BODYFONT }}>Products</ListSubheader>
            <List>
              <ListItem>
                <ListItemButton style={{fontFamily:BODYFONT,fontSize:'12px'}} onClick={()=>{
                          navigate('/fruits');
                        }}>Fruits</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton style={{fontFamily:BODYFONT,fontSize:'12px'}} onClick={()=>{
                          navigate('/millets');
                        }}>Millets</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton style={{fontFamily:BODYFONT,fontSize:'12px'}} onClick={()=>{
                          navigate('/vegetables');
                        }}>Vegetables</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
          <ListItem nested sx={{ width: { xs: '50%', md: 180 } }}>
            <ListSubheader sx={{ fontWeight: 'xl',fontFamily:BODYFONT }}>Contact Us</ListSubheader>
            <List>
            <ListItem style={{fontFamily:BODYFONT,fontSize:'12px'}}>
              <LocationOnOutlinedIcon/> Address
              </ListItem>
              <ListItem>
                <ListItemButton style={{fontFamily:BODYFONT,fontSize:'12px'}}>Sri Tirupati Exim, No 1461, Third Floor, 14th Cross Rd, Ananth Nagar, 2nd Phase, Bengaluru, Karnataka 560100</ListItemButton>
              </ListItem>
             
              <ListItem style={{fontFamily:BODYFONT,fontSize:'12px'}}>
               <MailOutlineOutlinedIcon/>E-Mail Address
              </ListItem>
              <ListItem>
                <ListItemButton style={{fontFamily:BODYFONT,fontSize:'12px'}} onClick={()=>{
                  window.location.href = 'mailto:info@sritirupatiexim.com';
                }}> info@sritirupatiexim.com</ListItemButton>
              </ListItem>
             
              
            </List>
          </ListItem>
          <ListItem nested sx={{ width: { xs: '50%', md: 180 } }}>
              <ListSubheader sx={{ fontWeight: 'xl',fontFamily:BODYFONT }}>Connect With Us</ListSubheader>
              <List>
              <ListItem style={{fontFamily:BODYFONT,fontSize:'12px'}}><IconButton onClick={()=>
                window.location.href='https://www.facebook.com/profile.php?id=61562502202773'
              }><FacebookIcon/></IconButton><IconButton onClick={()=>
                window.location.href='https://wa.me/919740991843?text=Hello%20I%20have%20an%20Enquiry.'
              }><WhatsAppIcon/></IconButton></ListItem>
              </List>
              </ListItem>
        </List>
      </Box>
    </Sheet>
  );
}