import tomato from './images/Tomato.jpg';
import Jowar from './images/Jowar.png';
import Bajra from './images/Bajra.png';
import Ragi from './images/Ragi.png';
import Arka from './images/arka.png';
import Oodalu from './images/Oodalu.png';
import Samai from './images/Samai.png';
import Navane from './images/Navane.png';
import Bargu from './images/bargu.png';
import Korale from './images/Korale.png';
import Mosambi from './images/Mosambi.jpg';
import Orange from './images/Orange.jpg';
import Muskmelon from './images/Muskmelon.jpg';
import Pomegranate from './images/pomegranates.jpg';
import Pumpkin from './images/Pumpkins.jpg';
import Brinjal from './images/brinjal.png';
import LadyFinger from './images/lady_finger.png';
import GreenChillies from './images/GreenChilli.jpg';

export  const ICONCOLORS = '#00aefe';
export const BODYFONT ="'Poppins', Arial, sans-serif";
export const PRODUCTS = [{jowar:{
    variantName:'Jowar',
    variantSource:Jowar,
    variantDescription:'Jowar, also known as sorghum, is a highly versatile and nutritious grain widely cultivated for food, fodder, and industrial uses.',
    variantPrice:'$1',
    }},
    {pearlmillet:{
    variantName:'Pearl Millet',
    variantSource:Bajra,
    variantDescription:'Bajra, also known as pearl millet, is one of the oldest cultivated grains and is highly valued for its nutritional benefits and resilience to harsh growing conditions. ',
    variantPrice:'$2',
    }},
    {fingermillet:{
    variantName:'Finger Millet',
    variantSource:Ragi,
    variantDescription:'Ragi, also known as finger millet, is a nutrient-rich grain with a long history of cultivation. It is valued for its nutritional content and adaptability to various growing conditions.',
    variantPrice:'$3',
    }},
    {kodomillet:{
    variantName:'Kodo Millet',
    variantSource:Arka,
    variantDescription:'Arka, also known as Kodo millet, is a nutritious and resilient grain that has been cultivated for thousands of years. It is particularly valued for its ability to grow in poor soil and drought-prone areas. ',
    variantPrice:'$4',
    }},
    {barnyardmillet:{
    variantName:'Barnyard Millet',
    variantSource:Oodalu,
    variantDescription:'Oodalu, also known as barnyard millet, is a highly nutritious and fast-growing grain that is well-suited to a variety of climates and soil types. It is particularly valued for its short growing season and resilience to poor soil conditions.',
    variantPrice:'$5',
    }},
    {littlemillet:{
    variantName:'Little Millet',
    variantSource:Samai,
    variantDescription:'Samai, also known as little millet, is a small-seeded grain that is highly valued for its nutritional benefits and adaptability to various growing conditions. It is an ancient grain that continues to be an important food source in many parts of the world.',
    variantPrice:'$6',
    }},
    {foxtailmillet:{
    variantName:'Foxtail Millet',
    variantSource:Navane,
    variantDescription:'Navane, also known as foxtail millet, is an ancient grain known for its nutritional benefits and adaptability to various growing conditions. It is a staple food in many parts of Asia and Africa.',
    variantPrice:'$7',
    }},
    {prosomillet:{
    variantName:'Proso Millet',
    variantSource:Bargu,
    variantDescription:"Bargu, also known as proso millet, is a hardy and nutritious grain that has been cultivated for thousands of years. It is valued for its resilience and nutritional benefits.",
    variantPrice:'$8',
    }},
    {browntopmillet:{
    variantName:'Brown Top Millet',
    variantSource:Korale,
    variantDescription:'Korale, also known as browntop millet, is a type of millet that holds significance in various agricultural and culinary practices.',
    variantPrice:'$9',
    }},
    {sweetlemon:{
      variantName:'Sweet Lemon',
      variantSource:Mosambi,
      variantDescription:"Mosambi, also known as sweet lime or sweet lemon, is a citrus fruit  and It has a sweet and sour taste, making it a popular choice for making juices, jams, and desserts. Rich in vitamin C and other nutrients, mosambi is a healthy snack\nSweet lemons are actually a hybrid, a mix of citron and bitter orange\n.There can be some confusion with another citrus fruit called the sweet lime, but they're not exactly the same\n.Look for them in specialty stores, as they're not quite as common as regular lemons.",
      variantPrice:'$0',
      }},
      {orange:{
      variantName:'Orange',
      variantSource:Orange,
      variantDescription:'Sweet oranges are a good source of vitamin C, which is an important nutrient for immune function and collagen production. They also contain fiber, potassium, and folate',
      variantPrice:'$0',
      }},
      {muskmelongold:{
      variantName:'Musk Melon Gold',
      variantSource:'https://cpimg.tistatic.com/05006090/b/5/Musk-Melon-F1-Golden-Honey-525.jpg',
      variantDescription:'Musk melon, also known as sweet melon, gets its name from its strong musky smell. It’s a yellow-colored fruit with tempting sweetness and a pleasant aroma. Musk melons are abundantly available in summer and have high water content, making them hydrating and cooling.',
      variantPrice:'$0',
      }},
      {muskmelon:{
      variantName:'Muskmelon',
      variantSource:Muskmelon,
      variantDescription:'Muskmelon, scientifically known as Cucumis melo, is a juicy and flavorful fruit that belongs to the gourd family, Cucurbitaceae. It’s popular for its sweet taste and refreshing texture',
      variantPrice:'$0',
      }},
      {pomegranate:{
      variantName:'Pomegranate',
      variantSource:Pomegranate,
      variantDescription:'Promegranates are low in calories and fat but high in fiber, vitamins, and minerals',
      variantPrice:'$0',
      }},
      {tomato:{
      variantName:'Tomato',
      variantSource:tomato,
      variantDescription:'The tomato is the edible berry of the plant Solanum lycopersicum, commonly known as the tomato plant',
      variantPrice:'$0',
      }},
      {ladyfinger:{
      variantName:'lady finger',
      variantSource:LadyFinger,
      variantDescription:'Lady finger, also known as okra, is a tropical vegetable prized for its unique flavor and versatility in culinary applications.',
      variantPrice:'$0',
      }},
      {pumpkin:{
      variantName:'Pumpkin',
      variantSource:Pumpkin,
      variantDescription:'Pumpkin is a member of the cucurbitaceae family and is one of hundreds of species of plants more commonly called gourds',
      variantPrice:'$0',
      }},
      {brinjal:{
      variantName:'Brinjal',
      variantSource:Brinjal,
      variantDescription:'Eggplant, aubergine, brinjal, or baigan is a plant species in the nightshade family Solanaceae',
      variantPrice:'$0',
      }},
      {onion:{
      variantName:'Onion',
      variantSource:'https://etimg.etb2bimg.com/photo/102673498.cms',
      variantDescription:'An onion, also known as the bulb onion or common onion, is a vegetable that is the most widely cultivated species of the genus Allium',
      variantPrice:'$0',
      }},
      {greenchillies:{
      variantName:'GREEN CHILLIES',
      variantSource:GreenChillies,
      variantDescription:'Green chillies are a rich source of many nutrients. The bioactive compounds include alkaloids, flavonoids, phenolics..etc',
      variantPrice:'$0',
      }}];