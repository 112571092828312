import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import EnquiredCountry from './enquiredcountry';
import { Typography } from '@mui/joy';
import Input from '@mui/joy/Input';
import {BODYFONT} from './constants'
import AddressDetails from './addressdetails';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Sheet from '@mui/joy/Sheet';
import { Grid } from "@mui/joy";
import { styled } from '@mui/joy/styles';
import Textarea from '@mui/joy/Textarea';
import { useParams } from "react-router-dom";
import Autocomplete from '@mui/joy/Autocomplete';
import { DateCalendar,DatePicker,LocalizationProvider     } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/joy/Box';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';




const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
  }));

export default function ContactUs(props) {
  const navigate = useNavigate();
  emailjs.init({
    publicKey: 'PoSZFQwaB0w0AVaXK',
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: ['foo@emailjs.com', 'bar@emailjs.com'],
      // The variable contains the email address
      watchVariable: 'userEmail',
    },
    limitRate: {
      // Set the limit rate for the application
      id: 'app',
      // Allow 1 request per 10s
      throttle: 5000,
    },
  });
    
    const [data,setData] = useState({
      name:'',
      country:'',
      subject:'',
      description:'',
      contactNumber:'',
      emailId:''
    });
    const formChange =(name,value) =>{
      data[name]  = value;
      setData((prevData) => ({ ...prevData, name: value }));
    };
  return (
    <form
    onSubmit={(event) => {
      event.preventDefault();

      emailjs.send("service_ymjw8u9","template_zvyggkn",{
        from_name: data.companyName,
        to_name: 'Tried Reaching With Contact',
        message: JSON.stringify(data),
        reply_to: data.emailId,
        }).then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
          },
          (error) => {
            console.log('FAILED...', error);
          },
        );
        navigate('/');
    }}
   
>
    <Grid container spacing={2} columns={16}>
 <Grid xs={16} md={8} lg={8} ><Item >
                <FormLabel sx={{fontFamily:BODYFONT,fontSize:'12px',fontWeight:'500'}}>Name:</FormLabel>
            <Input required placeholder="Type Name..." size="sm" sx={{  width: 400,fontFamily:BODYFONT,fontSize:'12px',fontWeight:'400' }} onChange={(event) => formChange('name',event.target.value)}/>
            <FormLabel sx={{fontFamily:BODYFONT,fontSize:'12px',fontWeight:'500'}}>Country</FormLabel>
            <EnquiredCountry formChange={formChange}/>
              <FormLabel sx={{fontFamily:BODYFONT,fontSize:'12px',fontWeight:'500'}}>Subject</FormLabel>
              <Input required placeholder="Subject" size="sm" sx={{  width: 400,fontFamily:BODYFONT,fontSize:'12px',fontWeight:'400' }}  onChange={(event) => formChange('subject',event.target.value)}/>
              <FormLabel sx={{fontFamily:BODYFONT,fontSize:'12px',fontWeight:'500'}}>Description</FormLabel>
           <Textarea required
  placeholder="Enter Details..."
  minRows={4}
  sx={{
    '&::before': {
      display: 'none',
    },
    '&:focus-within': {
      outline: '2px solid var(--Textarea-focusedHighlight)',
      outlineOffset: '2px',
    },
    fontFamily:BODYFONT,fontSize:'12px',fontWeight:'400',width: 400
  }}
  onChange={(event) => formChange('description',event.target.value)}
/>       

<FormLabel sx={{fontFamily:BODYFONT,fontSize:'12px',fontWeight:'500'}}>Contact Number:</FormLabel>
<Input required
  placeholder="Contact Number"
  name="Contact Number"
  type="number"
  sx={{  width: 400,fontFamily:BODYFONT,fontSize:'12px',fontWeight:'400' }} 
  onChange={(event) => formChange('contactNumber',event.target.value)}
  />
<FormLabel sx={{fontFamily:BODYFONT,fontSize:'12px',fontWeight:'500'}}>Email:</FormLabel>
<Input required
  placeholder="Email"
  name="Email Id"
  type="email"
  sx={{  width: 400,fontFamily:BODYFONT,fontSize:'12px',fontWeight:'400' }} 
  onChange={(event) => formChange('emailId',event.target.value)}
  />
</Item></Grid>

<Grid xs={16} md={16} lg={16} ><Item>

<Box
                sx={{
                  display: "flex",
                  gap: 1.5,
                  "& > button": { flex: 1 },
                }}
              >
                <Button
                  variant="solid"
                  color="primary"
                  size="sm"
                  sx={{ fontFamily: BODYFONT, fontSize: "12px" }}
                  onClick={() => {
                   
                  }}
                   type="submit"
                >
                  Submit
                </Button>
              </Box>
</Item></Grid>

      </Grid></form>
  );
}