import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Drawer from '@mui/joy/Drawer';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import ModalClose from '@mui/joy/ModalClose';
import Menu from '@mui/icons-material/Menu';
import Search from '@mui/icons-material/Search';
import NavigationMenu from './navbar';
import { Link } from 'react-router-dom';
import { BODYFONT,ICONCOLORS } from './constants';
import { ListItem } from '@mui/joy';
import ListDivider from '@mui/joy/ListDivider';
import OtherHousesRoundedIcon from '@mui/icons-material/OtherHousesRounded';
import { useNavigate } from 'react-router-dom';
export default function DrawerMobileNavigation() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <IconButton variant="outlined" color="neutral" onClick={() => setOpen(true)} sx={{float:'right'}}>
        <Menu />
      </IconButton>
      <Drawer open={open} onClose={() => setOpen(false)}>
            <List role="menubar" orientation="veritcal">
            <ListItemButton
            role="menuitem"
            component="a"
            aria-label="Home"
            onClick={()=>{setOpen(false);
              navigate('/');
            }} >
        <ListItem role="none">
        <Link to='/' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
         
           Home
          </Link>
        </ListItem></ListItemButton>
        <ListDivider sx={{ backgroundColor: '#d2ebeb' }}/>
        <ListItemButton role="menuitem"  onClick={()=>{setOpen(false);
              navigate('/fruits');
            }}>
        <Link to='/fruits' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
         
          Fruits
         
        </ListItem> </Link></ListItemButton>
        <ListDivider sx={{ backgroundColor: '#d2ebeb' }}/>
        <ListItemButton role="menuitem" onClick={()=>{setOpen(false);
              navigate('/millets');
            }}>
        <Link to='/millets' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
         
          Millets
         
        </ListItem> </Link></ListItemButton>
       <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
       <ListItemButton role="menuitem" onClick={()=>{setOpen(false);
              navigate('/vegetables');
            }}>
        <Link to='/vegetables' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
         
          Vegetables
         
        </ListItem> </Link></ListItemButton>
        <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
        <ListItemButton role="menuitem" onClick={()=>{setOpen(false);
              navigate('/aboutus');
            }}>
        <Link to='/aboutus' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
         
          About Us
         
        </ListItem> </Link></ListItemButton>
        <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
        <ListItemButton role="menuitem" onClick={()=>{setOpen(false);
              navigate('/contactus');
            }}>
        <Link to='/contactus' style={{  position: "inherit",display:"flex",   textDecoration:"none",color:'#000000',fontSize:'11px'}} >
        <ListItem role="none" style={{fontFamily:BODYFONT,fontSize:'11px'}}>
         
          Contact
         
        </ListItem> </Link></ListItemButton>
        <ListDivider sx={{ backgroundColor: '#d2ebeb'}}/>
      </List>
      </Drawer>
    </React.Fragment>
  );
}