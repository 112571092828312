import { useRef, useState,useEffect } from "react";
import Sheet from '@mui/joy/Sheet';
import { Grid } from "@mui/joy";
import { styled } from '@mui/joy/styles';
import { motion, useInView } from "framer-motion";
import Millet from "./millet";
import { useParams } from "react-router-dom";
import tomato from '../images/Tomato.jpg';
import Jowar from '../images/Jowar.png';
import Bajra from '../images/Bajra.png';
import Ragi from '../images/Ragi.png';
import Arka from '../images/arka.png';
import Oodalu from '../images/Oodalu.png';
import Samai from '../images/Samai.png';
import Navane from '../images/Navane.png';
import Bargu from '../images/bargu.png';
import Korale from '../images/Korale.png';
import Mosambi from '../images/Mosambi.jpg';
import Orange from '../images/Orange.jpg';
import Muskmelon from '../images/Muskmelon.jpg';
import Pomegranate from '../images/pomegranates.jpg';
import Pumpkin from '../images/Pumpkins.jpg';
import Brinjal from '../images/brinjal.png';
import LadyFinger from '../images/lady_finger.png';
import GreenChillies from '../images/greenchillies.png';

const millets = [{
  variantName:'Jowar',
  variantSource:Jowar,
  variantDescription:'Jowar, also known as sorghum, is a highly versatile and nutritious grain widely cultivated for food, fodder, and industrial uses.',
  variantPrice:'$1',
  },
  {
  variantName:'Pearl Millet',
  variantSource:Bajra,
  variantDescription:'Bajra, also known as pearl millet, is one of the oldest cultivated grains and is highly valued for its nutritional benefits and resilience to harsh growing conditions. ',
  variantPrice:'$2',
  },
  {
  variantName:'Finger Millet',
  variantSource:Ragi,
  variantDescription:'Ragi, also known as finger millet, is a nutrient-rich grain with a long history of cultivation. It is valued for its nutritional content and adaptability to various growing conditions.',
  variantPrice:'$3',
  },
  {
  variantName:'Kodo Millet',
  variantSource:Arka,
  variantDescription:'Arka, also known as Kodo millet, is a nutritious and resilient grain that has been cultivated for thousands of years. It is particularly valued for its ability to grow in poor soil and drought-prone areas. ',
  variantPrice:'$4',
  },
  {
  variantName:'Barnyard Millet',
  variantSource:Oodalu,
  variantDescription:'Oodalu, also known as barnyard millet, is a highly nutritious and fast-growing grain that is well-suited to a variety of climates and soil types. It is particularly valued for its short growing season and resilience to poor soil conditions.',
  variantPrice:'$5',
  },
  {
  variantName:'Little Millet',
  variantSource:Samai,
  variantDescription:'Samai, also known as little millet, is a small-seeded grain that is highly valued for its nutritional benefits and adaptability to various growing conditions. It is an ancient grain that continues to be an important food source in many parts of the world.',
  variantPrice:'$6',
  },
  {
  variantName:'Foxtail Millet',
  variantSource:Navane,
  variantDescription:'Navane, also known as foxtail millet, is an ancient grain known for its nutritional benefits and adaptability to various growing conditions. It is a staple food in many parts of Asia and Africa.',
  variantPrice:'$7',
  },
  {
  variantName:'Proso Millet',
  variantSource:Bargu,
  variantDescription:'Bargu, also known as proso millet, is a hardy and nutritious grain that has been cultivated for thousands of years. It is valued for its resilience and nutritional benefits.',
  variantPrice:'$8',
  },
  {
  variantName:'Brown Top Millet',
  variantSource:Korale,
  variantDescription:'Korale, also known as browntop millet, is a type of millet that holds significance in various agricultural and culinary practices.',
  variantPrice:'$9',
  }
];
const fruits = [
  {
    variantName:'sweet lemon',
    variantSource:Mosambi,
    variantDescription:'Mosambi, also known as sweet lime or sweet lemon, is a citrus fruit  and It has a sweet and sour taste, making it a popular choice for making juices, jams, and desserts. Rich in vitamin C and other nutrients, mosambi is a healthy snack',
    variantPrice:'$0',
    },
    {
    variantName:'Orange',
    variantSource:Orange,
    variantDescription:'Sweet oranges are a good source of vitamin C, which is an important nutrient for immune function and collagen production. They also contain fiber, potassium, and folate',
    variantPrice:'$0',
    },
    {
    variantName:'Muskmelon',
    variantSource:Muskmelon,
    variantDescription:'Muskmelon, scientifically known as Cucumis melo, is a juicy and flavorful fruit that belongs to the gourd family, Cucurbitaceae. It’s popular for its sweet taste and refreshing texture',
    variantPrice:'$0',
    },
    {
    variantName:'Pomegranate',
    variantSource:Pomegranate,
    variantDescription:'Promegranates are low in calories and fat but high in fiber, vitamins, and minerals',
    variantPrice:'$0',
    }
  
];
const vegetables=[
  {
    variantName:'Tomato',
    variantSource:tomato,
    variantDescription:'The tomato is the edible berry of the plant Solanum lycopersicum, commonly known as the tomato plant',
    variantPrice:'$0',
    },
    {
    variantName:'lady finger',
    variantSource:LadyFinger,
    variantDescription:'Ladyfinger, popularly known as bhindi in India, is rich in nutrients. It is considered a good source of carbohydrates, proteins, vitamins..etc',
    variantPrice:'$0',
    },
    {
    variantName:'Pumpkin',
    variantSource:Pumpkin,
    variantDescription:'Pumpkin is a member of the cucurbitaceae family and is one of hundreds of species of plants more commonly called gourds',
    variantPrice:'$0',
    },
    {
    variantName:'Brinjal',
    variantSource:Brinjal,
    variantDescription:'Eggplant, aubergine, brinjal, or baigan is a plant species in the nightshade family Solanaceae',
    variantPrice:'$0',
    },
    {
    variantName:'Onion',
    variantSource:'https://etimg.etb2bimg.com/photo/102673498.cms',
    variantDescription:'An onion, also known as the bulb onion or common onion, is a vegetable that is the most widely cultivated species of the genus Allium',
    variantPrice:'$0',
    },
    {
    variantName:'GREEN CHILLIES',
    variantSource:GreenChillies,
    variantDescription:'Green chillies are a rich source of many nutrients. The bioactive compounds include alkaloids, flavonoids, phenolics..etc',
    variantPrice:'$0',
    }

];
const variants = {
    initial: {
      y: 500,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };
  const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
  }));
  
export default function Millets() {
  

  const {product} = useParams();
  const ref = useRef();
  const[produces,setProduces] = useState(null); 
  const isInView = useInView(ref, { margin: "-100px" });

    const reactProducts = [];

    useEffect(() => {
     if(product=='millets') {
      setProduces(millets);
     } else if(product=='fruits') {
      setProduces(fruits);
     }
     else if(product=='vegetables') {
      setProduces(vegetables);
     }
    }, [product]);
    if(produces==null)
      return 'Loading....';
    produces.map((product,key) =>
      reactProducts.push(<Grid xs={16} md={4} lg={4} key={key}><Item key={key}><Millet key={key} variantName={product.variantName} variantSource={product.variantSource} variantPrice={product.variantPrice} variantDescription={product.variantDescription}/></Item></Grid>)
    );
   return(     <motion.div
    className="services"
    variants={variants}
    initial="initial"
    // animate="animate"
    //whileInView="animate"
    ref={ref}
    key={product}
    animate={"animate"}
    >
    <Grid container spacing={2} columns={16} sx={{ flexGrow: 1,float:'right', paddingLeft:'20px'}}>
    {reactProducts}

    </Grid>
    </motion.div>);

}