import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import { useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded';
import { BODYFONT, ICONCOLORS } from '../constants';
import Sheet from '@mui/joy/Sheet';
import Tooltip from '@mui/joy/Tooltip';
import { useNavigate } from 'react-router-dom';

const variants = {
  initial: {
    y: 500,
    opacity: 0,
    zIndex:0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};
export default function Millet(props) {
  const [rotate,setRotate] = useState(false);
  const navigation = useNavigate();
  const navigateProduct = (productName) =>{
    navigation('/product/'+productName);
  };
  return (
    <motion.div className="textContainer" variants={variants}
        >
    <Card onMouseEnter={()=>setRotate(!rotate)} onMouseLeave={()=>setRotate(!rotate)}>
      <div>
        <Typography level="title-sm">{props.variantName}</Typography>
      </div>
      <AspectRatio minHeight="120px" maxHeight="200px">
        <img
          src={props.variantSource}
          srcSet={props.variantSource}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      <CardOverflow variant="soft">
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Typography level="body-xs" sx={{fontFamily:BODYFONT}}>

          {props.variantDescription.length>120?props.variantDescription.substring(0,120)+'...':props.variantDescription}
          </Typography>
        </CardContent>
      </CardOverflow>
      <CardContent orientation="horizontal">
      
        <IconButton variant="light"
          size="sm"
          color="secondary"
          aria-label={props.variantDescription}
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600,backgroundColor:'black' }} onClick={()=>{
            navigateProduct(props.variantName.replaceAll(' ','').toLowerCase());
          }}>
      <Tooltip title="know more.." arrow  placement="right">
        <ReadMoreRoundedIcon sx={{color:'white'}}/></Tooltip></IconButton>
        
      </CardContent>
    </Card></motion.div>
  );
}
