import { useRef, useState } from "react";
import Product from "./product";
import Sheet from '@mui/joy/Sheet';
import { Grid } from "@mui/joy";
import { styled } from '@mui/joy/styles';
import { motion, useInView } from "framer-motion";

const products = [{
  variantName:'Orange Fruit',
  variantSource:'https://images.pexels.com/photos/2135677/pexels-photo-2135677.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
  variantName:'Maze Produced',
  variantSource:'https://images.pexels.com/photos/96715/pexels-photo-96715.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    variantName:'Maze Produced',
    variantSource:'https://images.pexels.com/photos/2733918/pexels-photo-2733918.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      variantName:'Maze Produced',
      variantSource:'https://images.pexels.com/photos/96715/pexels-photo-96715.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
      },
      {
        variantName:'Maze Produced',
        variantSource:'https://images.pexels.com/photos/2733918/pexels-photo-2733918.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
        },
        {
          variantName:'Maze Produced',
          variantSource:'https://images.pexels.com/photos/96715/pexels-photo-96715.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
          },
          {
            variantName:'Maze Produced',
            variantSource:'https://images.pexels.com/photos/2733918/pexels-photo-2733918.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            },
            {
              variantName:'Maze Produced',
              variantSource:'https://images.pexels.com/photos/2733918/pexels-photo-2733918.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              }
];
const variants = {
    initial: {
      y: 500,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };
  const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
  }));
  
export default function ItemBox() {
  
  const ref = useRef();

  const isInView = useInView(ref, { margin: "-100px" });
    const reactProducts = [];
    products.map((product,key) =>
      reactProducts.push(<Grid xs={16} md={8} lg={4} key={key}><Item key={key}><Product key={key} variantName={product.variantName} variantSource={product.variantSource}/></Item></Grid>)
    );
   return(     <motion.div
    className="services"
    variants={variants}
    initial="initial"
    // animate="animate"
    // whileInView="animate"
    ref={ref}
    animate={"animate"}
    >
    <Grid container spacing={4} columns={16} sx={{ flexGrow: 1,float:'right' }}>
    {reactProducts}

    </Grid>
    </motion.div>);

}