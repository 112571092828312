import * as React from "react";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { Grid, List, ListItem } from "@mui/joy";
import CustomerTrust from "./customertrust.jpg";
import { BODYFONT } from "./constants";
import { styled } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import Trust from "./images/trusthand.jpg";
const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.level1 : "#fff",
  ...theme.typography["body-sm"],
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));
export default function AboutUs() {
  return (
    <>
      <Grid
        container
        spacing={0}
        columns={16}
        sx={{ flexGrow: 1, margin: "0px" }}
      >
        <Grid xs={16} md={15} lg={15}>
          <Item>
            
            <Card
              sx={{
                width: "100%",
                flexWrap: "wrap",
                [`& > *`]: {
                  "--stack-point": "500px",
                  minWidth:
                    "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                },
              }}
            >
               <CardContent>
               <Typography level="title-lg" fontFamily={BODYFONT}>
                Who We Are:
              </Typography>
              <Typography level="body-sm" fontWeight="md" fontFamily={BODYFONT}>
                Sri Tirupati Exim is a leading international agricultural export
                business, passionate about bringing the freshest, finest farm
                produce to tables around the world. Founded on 2024, we have a
                long-standing commitment to sustainable practices and delivering
                exceptional quality to our global partners.
              </Typography>
              </CardContent></Card></Item></Grid>
              <Grid xs={16} md={15} lg={15}>
          <Item>
            
            <Card
              sx={{
                width: "100%",
                flexWrap: "wrap",
                [`& > *`]: {
                  "--stack-point": "500px",
                  minWidth:
                    "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                },
              }}
            >
               <CardContent>
               <Typography level="title-lg" fontFamily={BODYFONT}>
                Our Mission:
              </Typography>
              <Typography level="body-sm" fontWeight="md" fontFamily={BODYFONT}>
                Our mission is to bridge the gap between farms and international
                markets, ensuring that our customers receive the highest quality
                agricultural products while promoting sustainable and ethical
                sourcing practices.
              </Typography>
              </CardContent></Card></Item></Grid>
              <Grid xs={16} md={15} lg={15}>
          <Item>
          <Card
              sx={{
                width: "100%",
                flexWrap: "wrap",
                [`& > *`]: {
                  "--stack-point": "500px",
                  minWidth:
                    "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                },
              }}
            >
               <CardContent>
               <Typography level="title-lg" fontFamily={BODYFONT}>
                Cultivating Excellence:
              </Typography>
              <Typography level="body-sm" fontWeight="md" fontFamily={BODYFONT}>
                We implement rigorous quality control measures throughout the
                farming process, from seed selection to harvest, ensuring our
                products consistently meet the highest standards.
              </Typography>
              </CardContent></Card></Item></Grid>
              <Grid xs={16} md={15} lg={15}>
          <Item>
          <Card
              sx={{
                width: "100%",
                flexWrap: "wrap",
                [`& > *`]: {
                  "--stack-point": "500px",
                  minWidth:
                    "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                },
              }}
            >
               <CardContent>
               <Typography level="title-lg" fontFamily={BODYFONT}>
                Sustainable Practices:
              </Typography>
              <Typography level="body-sm" fontWeight="md" fontFamily={BODYFONT}>
                We are committed to environmental responsibility. We utilize
                eco-friendly farming methods that minimize our impact on the
                land and conserve natural resources.
              </Typography>
              </CardContent></Card></Item></Grid>
              <Grid xs={16} md={15} lg={15}>
          <Item>
          <Card
              sx={{
                width: "100%",
                flexWrap: "wrap",
                [`& > *`]: {
                  "--stack-point": "500px",
                  minWidth:
                    "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                },
              }}
            >
               <CardContent>
               <Typography level="title-lg" fontFamily={BODYFONT}>
                Building Partnerships:
              </Typography>
              <Typography level="body-sm" fontWeight="md" fontFamily={BODYFONT}>
                We believe in fostering strong relationships with our growers
                and international buyers. We work collaboratively to provide a
                reliable and transparent supply chain.
              </Typography>
              </CardContent></Card></Item></Grid>
              <Grid xs={16} md={15} lg={15}>
          <Item>
          <Card
              sx={{
                width: "100%",
                flexWrap: "wrap",
                [`& > *`]: {
                  "--stack-point": "500px",
                  minWidth:
                    "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                },
              }}
            >
               <CardContent>
               <Typography level="title-lg" fontFamily={BODYFONT}>
                Why Choose Us?
              </Typography>
              <Typography level="body-sm" fontWeight="md" fontFamily={BODYFONT}>
                Unmatched Quality: We prioritize superior taste, freshness, and
                consistent quality across all our products. </Typography>
                </CardContent>
            </Card>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
