import logo from './logo.svg';
import './App.css';
import DrawerMobileNavigation from './component/mobilenav';
import NavigationMenu from './component/navbar';
import Header from './component/header';
import ItemBox from './component/productboxes/itembox';
import ColorInversionFooter from './component/footer/footer';
import Millets from './component/millets/millets';
import bgimg from './bgheader.jpeg'
import {
  BrowserRouter as Router,
  Routes,
  Route, Redirect ,withRouter 
} from "react-router-dom";
import HomePage from './component/homepage';
import MainProduct from './component/productboxes/singleitemmain';
import EnquireForm from './component/enquireform';
import AboutUs from './component/aboutus';
import ContactUs from './component/contactus';
function App() {
  const pathName = window.location.pathname; 
  return (
  <>
  <div style={{display:"grid"}}>

  <Header/>
  <div style={{paddingTop:'30px'}}>
  <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/:product" element={<Millets/>}/>
      <Route path="/product/:productname" element={<MainProduct/>}/>
      <Route path="/quote/:productname" element={<EnquireForm/>}/>
      <Route path="/aboutus" element={<AboutUs/>}/>
      <Route path="/contactus" element={<ContactUs/>}/>
  </Routes></div>
<ColorInversionFooter></ColorInversionFooter>

  </div>
  </>
  );
}

export default App;
