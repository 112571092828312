import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { Grid, List, ListItem } from '@mui/joy';
import CustomerTrust from './customertrust.jpg';
import { BODYFONT } from './constants';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Chillies from './images/GreenChilli.jpg';
import Trust from './images/trusthand.jpg'
import { motion } from "framer-motion";
import LadyFingers from './images/lady_finger.png';
import Tomato from './images/tomato.jpeg';
import Flight from './images/AirCargo.jpg'
import { useNavigate } from 'react-router-dom';
import CardActions from '@mui/joy/CardActions';

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
  ...theme.typography['body-sm'],
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));
const variants = {
  initial: {
    y: 500,
    opacity: 0,
    zIndex:0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};
export default function HomePage(props) {
  const size = CustomerTrust +"?auto=format&fit=crop&w=320";
  const size2 = CustomerTrust+"?auto=format&fit=crop&w=320&dpr=2 2x";
  const navigate = useNavigate();
    return (
      <>
        <Grid container spacing={1} columns={16} sx={{  flexGrow: 1,float:'right'}}>
          <Grid xs={16} sm={16} md={12} lg={12}>
            <Item>
              <Box
                sx={{
                  
                  position: "relative",
                  overflow: { xs: "auto", sm: "initial" },
                }}
              >
                <Card
                  orientation="horizontal"
                  sx={{
                    width: "inherit",
                    flexWrap: "wrap",
                    [`& > *`]: {
                      "--stack-point": "500px",
                      minWidth:
                        "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      fontSize="xl"
                      fontWeight="lg"
                      fontFamily={BODYFONT}
                      sx={{ textDecoration: "underline" }}
                    >
                      Trust
                    </Typography>
                    <Typography
                      level="body-sm"
                      fontWeight="lg"
                      textColor="text.tertiary"
                      fontFamily={BODYFONT}
                    >
                      Our Commitment to Global Quality
                    </Typography>
                    <Sheet
                      sx={{
                        borderRadius: "sm",
                        p: 1.5,
                        my: 1.5,
                        display: "flex",
                        gap: 2,
                        "& > div": { flex: 1 },
                        fontFamily: BODYFONT,
                      }}
                    >
                      <div>
                        <Typography
                          level="body-xs"
                          fontWeight="md"
                          fontFamily={BODYFONT}
                        >
                          We take pride in delivering fresh, high-quality
                          agricultural products to the world. From farm to port,
                          we prioritize strict quality control, ensuring our
                          produce meets the highest international standards.
                        </Typography>
                      </div>
                    </Sheet>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1.5,
                        "& > button": { flex: 1 },
                      }}
                    >
                      <Button
                        variant="soft"
                        color="primary"
                        size="sm"
                        sx={{ fontFamily: BODYFONT, fontSize: "12px" }}
                        onClick={()=>{
                          navigate('/aboutus');
                        }}
                      >
                        Find out more...
                      </Button>
                    </Box>
                  </CardContent>
                  <AspectRatio
                    flex
                    ratio="1"
                    maxHeight={193}
                    sx={{ minWidth: 350 }}
                  >
                    <img src={Trust} srcSet={Trust} loading="lazy" alt="" />
                  </AspectRatio>
                </Card>
              </Box>
            </Item>
          </Grid>
          <Grid xs={0} md={4} lg={4}>
            <Item sx={theme => ({
      [theme.breakpoints.down('md')]: {
     display:'none'
      }
    })}>
              <Typography
                fontSize="sm"
                fontWeight="md"
                fontFamily={BODYFONT}
                sx={{ borderBottom: "1px solid rgb(100 100 100 / 0.66)" }}
              >
                Ready to Export
              </Typography>
              <motion.div
                variants={variants}
                whileHover={{ x: 5, y: 0, transition: 0.5 }}
                whileTap={{ scale: 0.9, zIndex: 0 }}
              >
                <Card sx={{ top: "10px" }}>
                  <AspectRatio minHeight="80px" maxHeight="110px">
                    <img
                      src={Chillies}
                      srcSet={Chillies}
                      loading="lazy"
                      alt=""
                    />
                  </AspectRatio>
                  <CardContent orientation="horizontal">
                    <Typography level="body-xs" sx={{ fontFamily: BODYFONT }}>
                      Fresh, Fiery Flavor: Export-Grade Green Chilies
                    </Typography>
                  </CardContent>
                  <CardActions buttonFlex="30 1 120px">
        <Button size="sm" variant="soft" color="neutral"  onClick={()=>{
                          navigate('/product/greenchillies');
                        }}>
          View
        </Button>

      </CardActions>
                </Card>
              </motion.div>
            </Item>
          </Grid>
          <Grid xs={16} sm={16} md={12} lg={12}>
            <Item>
            <Box
                sx={{
                  
                  position: "relative",
                  overflow: { xs: "auto", sm: "initial" },
                }}
              >
                <Box />
                <Card
                  orientation="horizontal"
                  sx={{
                    width: "inherit",
                    flexWrap: "wrap",
                    [`& > *`]: {
                      "--stack-point": "500px",
                      minWidth:
                        "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                    },
                  }}
                >
                   <AspectRatio
                    flex
                    ratio="1"
                    maxHeight={193}
                    sx={{ minWidth: 379 }}
                  >
                    <img src={Tomato} srcSet={Tomato} loading="lazy" alt="" />
                  </AspectRatio>
                  <CardContent>
                    <Typography
                      fontSize="xl"
                      fontWeight="lg"
                      fontFamily={BODYFONT}
                      sx={{ textDecoration: "underline" }}
                    >
                      Agricultural Exports
                    </Typography>
                    <Typography
                      level="body-xs"
                      fontWeight="md"
                      textColor="text.tertiary"
                      fontFamily={BODYFONT}
                    /><Typography
                    level="body-xs"
                    fontWeight="md"
                    textColor="text.tertiary"
                    fontFamily={BODYFONT}
                  >
                    Consistent Supply: We have robust systems in place to ensure a year-round supply of our agricultural products. This reliability allows you to consistently integrate our produce into your offerings.</Typography>
                    <Typography
                      level="body-xs"
                      fontWeight="md"
                      textColor="text.tertiary"
                      fontFamily={BODYFONT}
                    > Sustainable Practices: We are committed to environmentally responsible agriculture. We focus on water conservation, soil health, and responsible waste management, ensuring the long-term viability of our practices.
                    </Typography>
                   
                     
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1.5,
                        "& > button": { flex: 1 },
                      }}
                    >
                      <Button
                        variant="soft"
                        color="primary"
                        size="sm"
                        sx={{ fontFamily: BODYFONT, fontSize: "12px" }} onClick={()=>{
                          navigate('/vegetables');
                        }}
                      >
                        Find out more...
                      </Button>
                    </Box>
                  </CardContent>
                 
                </Card>
              </Box>
            </Item>
          </Grid>

          <Grid xs={0} md={4} lg={4}>
            <Item sx={theme => ({
      [theme.breakpoints.down('md')]: {
     display:'none'
      }
    })}>
              <motion.div
                variants={variants}
                whileHover={{ x: 5, y: 0, transition: 0.5 }}
                whileTap={{ scale: 0.9, zIndex: 0 }}
              >
                <Card sx={{ top: "0px" }}>
                  <AspectRatio minHeight="80px" maxHeight="110px">
                    <img
                      src={LadyFingers}
                      srcSet={LadyFingers}
                      loading="lazy"
                      alt=""
                    />
                  </AspectRatio>
                  <CardContent orientation="horizontal">
                    <Typography level="body-xs" sx={{ fontFamily: BODYFONT }}>
                    Elevate your dishes with our vibrant, export-quality ladyfingers!
                    </Typography>
                  </CardContent>
                  <CardActions buttonFlex="30 1 120px">
        <Button size="sm" variant="soft" color="neutral" onClick={()=>{
                          navigate('/product/ladyfinger');
                        }}>
          View
        </Button>

      </CardActions>
                </Card>
              </motion.div>
            </Item>
</Grid>
          <Grid xs={16} sm={16} md={15.8} lg={15.8}>
            <Item>
            <Box
                sx={{
                  
                  position: "relative",
                  overflow: { xs: "auto", sm: "initial" },
                }}
              >
                <Box />
                <Card
                  orientation="horizontal"
                  sx={{
                    width: "inherit",
                    flexWrap: "wrap",
                    [`& > *`]: {
                      "--stack-point": "500px",
                      minWidth:
                        "clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      fontSize="xl"
                      fontWeight="lg"
                      fontFamily={BODYFONT}
                      sx={{ textDecoration: "underline" }}
                    >
                      Shipment
                    </Typography>
                    <Typography
                      level="body-sm"
                      fontWeight="lg"
                      textColor="text.tertiary"
                      fontFamily={BODYFONT}
                    >
                      Air Cargo
                    </Typography>
                    <Sheet
                      sx={{
                        borderRadius: "sm",
                        p: 1.5,
                        my: 1.5,
                        display: "flex",
                        gap: 2,
                        "& > div": { flex: 1 },
                        fontFamily: BODYFONT,
                      }}
                    >
                      <div>
                        <Typography
                          level="body-xs"
                          fontWeight="md"
                          fontFamily={BODYFONT}
                        >
 Get your hands on the freshest produce possible. We utilize air cargo to ensure the fastest possible delivery, minimizing travel time and preserving peak quality.
 Our air cargo option is perfect for delicate vegetables with short shelf lives, like leafy greens, asparagus, or berries. They arrive crisp, vibrant, and bursting with flavor.
                        </Typography>
                      </div>
                    </Sheet>
                    
                  </CardContent>
                  <AspectRatio
                    flex
                    ratio="1"
                    maxHeight={193}
                    sx={{ minWidth: 240 }}
                  >
                    <img src={Flight} srcSet={Flight} loading="lazy" alt="" />
                  </AspectRatio>
                  </Card></Box>
            </Item>
          </Grid>
        </Grid>
      </>
    );
}